import React from "react";
import { BackTop } from 'antd';
import logo from "../../assets/images/primevlsi.jpg"

function AppFooter() {
    return(
        <div className="container-fluid">
            <div className="footer">
                <div className="logo">
                    <i ><img src={logo} alt="footer-logo" className="logoimage"/></i>
                    {/* <a href="#">Tetra Silicon</a> */}
                </div>
                <i className="fas fa-map-marker-alt footer-icon"></i>
                <div className='address'>
                <span className="address-content">Sri Sai Complex,<br /> Kodiginahalli main road, <br />KR Puram, Bangalore, 560036</span>
                </div><br />
                <div className="copyright"> © {(new Date().getFullYear())} Prime VLSI - All Rights Reserved</div>
                <BackTop>
                    <div className="goTop"><i className="fas fa-arrow-circle-up"></i></div>
                 </BackTop>
            </div>
        </div>
    );
}

export default AppFooter;