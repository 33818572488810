import React,{ useEffect } from "react";
import { Carousel, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { TimelineLite, Power3 } from "gsap/all";
import AppFooter from "./Footer";

const { Panel } = Collapse;

const sample = [
    {
        key: '1',
        title: "Careers",
        name: "careerpage",
    },
   
]


function Career() {
    useEffect( () => {
        let animation = new TimelineLite({ delay: 0.3});
        animation.staggerFrom('.careerpage', 1, {y: 50, ease: Power3.easeOut, opacity: 0 }, 0.15, 'start')
        .from(".contentHolder", { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.2}, 0.35, 'start');
    }, [])
    return(
        <>
         <Carousel>
                {
                sample.map(item => {
                    return(
                        <div key={item.key} className={item.name}>
                            <div className="container-fluid" key={item.key}>
                                <div className="content">
                                    <h3>{item.title}</h3>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        <div id="career" className="block careerBlock">
            <div className="container-fluid">
                <div className="contentHolder">
                    <p className="career-content">Prime VLSI  is a dynamic and global company with countless opportunities for career development. Being a complete technological organization, we offer our employees with exciting opportunities to work on latest technologies. We inspire our people to successfully unleash their full career potential synergized with our business growth.</p>
                </div>
                <Collapse bordered={false} defaultActiveKey={['1']} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}  className="site-collapse-custom-collapse">
                    <Panel header="Physical Design Engineer" key="1" className="site-collapse-custom-panel">
                        <p>Job Type : Full Time</p>
                        <p>Experience : 2-15 years</p>
                        <p>Job Description :  Hands on experience in Block level PnR convergence with Synopsys ICC2/Cadence Innovus and timing convergence in PTSI/Tempus. In this position, candidate is expected to lead all block/chip level PD activities including floor plans, placement, CTS, optimization and routing techniques, RC extraction, STA, EM/IR DROP, PV. Familiar with deep sub-micron designs below 10nm preferred. </p>
                        <p>Qualification : BE/B.Tech/ME/M.TECH.</p>
                    </Panel>
                    <Panel header="Full Stack Web Development" key="2" className="site-collapse-custom-panel">
                        <p>Job Type : Full Time</p>
                        <p>Experience : 2-10 years</p>
                        <p>Skills Required : MERN STACK, SQL and Firebase.</p>
                        <p>Job Description : Required hands on experience in Creating website layout and user interface by using standard web technologies. Willing to designed and testable the efficient code by using best software development practices.</p>
                        <p>Qualification : BE/B.Tech or equivalent Degree.</p>
                    </Panel>
                    <Panel header="Software Testing (Manual and Automation)" key="3" className="site-collapse-custom-panel">
                        <p>Job Type : Full Time</p>
                        <p>Experience : 2-10 years</p>
                        <p>Skills Required : Core Java, Selenium-Junit/TestNG/Cucumber, JDBC, BDD Framework, Jenkins, Jira and SQL.</p>
                        <p>Job Description : Required hands on experience in Develop and execute automated test scripts using selenium with core java. Enhance existing automation framework developed in Selenium. Support overall QA activities such as development of Test approach, Test cases, and manual test execution as needed.</p>
                        <p>Qualification : BE/B.Tech or equivalent Degree.</p>
                    </Panel>
                </Collapse>
            </div>
        </div>
        </>
    );
}

export default Career;