import React,{useState, useEffect} from "react";
import ReactCardFlip from 'react-card-flip';
import { Card, Button, Row, Col, Carousel } from "antd";
import { TimelineLite, Power3 } from "gsap/all";

const { Meta } = Card;

const sample = [
    {
        key: '1',
        title: "Contact",
        name: "contactpage"
    },
   
]

function Contact() {
    const [isFlipped, setIsFlipped] = useState(false);
    useEffect( () => {
        let animation = new TimelineLite({ delay: 0.3});
        animation.staggerFrom('.contactpage', 1, {y: 50, ease: Power3.easeOut, opacity: 0 }, 0.15, 'start')
        .from(".contentHolder", { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.2}, 0.35, 'start');
    }, [])
    const handleClick = (e) => {
        e.preventDefault();
        setIsFlipped(!isFlipped);
    }
    return(
        <>
         <Carousel>
                {
                sample.map(item => {
                    return(
                        <div key={item.key} className={item.name}>
                            <div className="container-fluid" key={item.key}>
                                <div className="content">
                                    <h3>{item.title}</h3>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        <div id="contact" className="block contactBlock">
            <div className="container-fluid">
                <div className="contentHolder">
                    <Row gutter={[48, 16]}>
                       <Col lg={{ span: 12}} md={{ span: 12}} sm={{ span: 12}}>
                           <Card hoverable  className="contentMainCard">
                               <p className="contentCard">Prime VLSI is a high level of expertise in Semiconductor design, Embedded software development including applications and Software Development in website building and testing.</p>
                            </Card>
                       </Col>
                        <Col lg={{ span: 12}} md={{ span: 12}} sm={{ span: 12}}>
                            <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
                                <Card hoverable>
                                   <Meta title="hr@primevlsi.in" description="Email" />
                                    <div className="btnFlip">
                                       <Button type="primary" onClick={handleClick}>Click to flip</Button>
                                    </div>
                                </Card>
                                <Card hoverable>
                                    <Meta title="+91-8639195240" description="Contact Number" />
                                    <div className="btnFlip">
                                       <Button type="primary" onClick={handleClick}>Click to flip</Button>
                                    </div>
                                </Card>
                            </ReactCardFlip> 
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        </>
    );
}

export default Contact;