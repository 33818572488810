import React,{useEffect} from "react";
import { Row, Col, Card, Carousel } from 'antd';
import imageSemi from "../../assets/images/semiconductor.jpg";
import { TimelineLite, Power3 } from "gsap/all";

const { Meta } = Card;

const sample = [
    {
        key: '1',
        title: "Embedded Design",
        name: "embeddedpage",
    },
   
]


function Embedded(){
    useEffect( () => {
        let animation = new TimelineLite({ delay: 0.3});
        animation.staggerFrom('.contentHolder', 1, {y: 50, ease: Power3.easeOut, opacity: 0 }, 0.15, 'start')
        animation.staggerFrom('.embeddedpage', 1, {y: 50, ease: Power3.easeOut, opacity: 0 }, 0.15, 'start')
        .from(".embedded-row", { y: 30, opacity: 0, ease: Power3.easeOut, delay: 0.2}, 0.35, 'start');
    }, [])
    return(
        <>
        <Carousel>
               {
               sample.map(item => {
                   return(
                       <div key={item.key} className={item.name}>
                           <div className="container-fluid" key={item.key}>
                               <div className="content">
                                   <h3>{item.title}</h3>
                               </div>
                           </div>
                       </div>
                   );
               })}
           </Carousel>
        <div id="semiconductor" className="block embeddedBlock">
            <div className="container-fluid">
                <div className="contentHolder">
                    <p className="embedded-content">The demand for high performance, low power consumption and low form-factor designs has dramatically increased in the past few years. Prime VLSI is proud to have smart working engineers with expertise in various domains of the chip design cycle. We provide quality engineering services in the area of Embedded Product Design and Embedded Software Development. Embedded hardware and software for Industrial and IoT products. Design and development of high-speed digital and mixed-signal PCBs</p>
                </div>
                <Row gutter={[48, 32]} className="embedded-row">
                    <Col lg={{ span: 12}} md={{ span: 8}} sm={{ span: 12}}>
                        <Card hoverable cover={<img alt="example" src={imageSemi} className="embedded-card" />} >
                        <Meta title="Embedded Design" />
                        </Card>
                     </Col>
                    <Col lg={{ span: 12}} md={{ span: 8}} sm={{ span: 12}}>
                        <ul className="embedded-list">
                            <li className="embedded-list-element"><i class="fas fa-angle-right"></i> Embedded Product Design</li>
                            <li className="embedded-list-element"><i class="fas fa-angle-right"></i> Embedded Software Development</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            
        </div>
        </>
    );
}

export default Embedded;