import React,{useEffect} from "react";
import { Row, Col, Card, Carousel } from 'antd';
import imageEmb from "../../assets/images/embedded.jpg";
import { TimelineLite, Power3 } from "gsap/all";


const { Meta } = Card;

const sample = [
    {
        key: '1',
        title: "Semiconductor",
        name: "semiconductorpage",
    },
   
]

function Semiconductor(){
    useEffect( () => {
        let animation = new TimelineLite({ delay: 0.3});
        animation.staggerFrom('.contentHolder', 1, {y: 50, ease: Power3.easeOut, opacity: 0 }, 0.15, 'start')
        animation.staggerFrom('.semiconductorpage', 1, {y: 50, ease: Power3.easeOut, opacity: 0 }, 0.15, 'start')
        .from(".semiconductor-row", { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.2}, 0.35, 'start');
    }, [])
    return(
        <>
        <Carousel>
               {
               sample.map(item => {
                   return(
                       <div key={item.key} className={item.name}>
                           <div className="container-fluid" key={item.key}>
                               <div className="content">
                                   <h3>{item.title}</h3>
                               </div>
                           </div>
                       </div>
                   );
               })}
           </Carousel>
        <div id="semiconductor" className="block semiconductorBlock">
            <div className="container-fluid">
                <div className="contentHolder">
                    <p className="semiconductor-content">The demand for high performance, low power consumption and low form-factor designs has dramatically increased in the past few years. Prime VLSI is proud to have smart working engineers with expertise in various domains of the chip design cycle. We provide quality engineering services in the area of ASIC Design/SoC Verification, Physical Design, Design for Testability (DFT) and Design Verification & Validation.</p>
                </div>
                <Row gutter={[48, 32]} className="semiconductor-row">
                    <Col lg={{ span: 12}} md={{ span: 8}} sm={{ span: 12}}>
                        <Card hoverable cover={<img alt="example" src={imageEmb} className="semicondutor-card" />} >
                        <Meta title="Semiconductor" />
                        </Card>
                     </Col>
                    <Col lg={{ span: 12}} md={{ span: 8}} sm={{ span: 12}}>
                        <ul className="semiconductor-list">
                            <li className="semiconductor-list-element"><i class="fas fa-angle-right"></i> ASIC Design/SoC Verification
                            </li>
                            <li className="semiconductor-list-element"><i class="fas fa-angle-right"></i> Physical Design</li>
                            <li className="semiconductor-list-element"><i class="fas fa-angle-right"></i> Design for Testability (DFT)</li>
                            <li className="semiconductor-list-element"><i class="fas fa-angle-right"></i> Design Verification and Validation</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            
        </div>
        </>
    );
}

export default Semiconductor;